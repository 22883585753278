<template>
  <div class="page-content">
    <div class="container-fluid">
      <div v-if="!withdraw">
        <withdraw-shimmer />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-lg-12">
            <div class="card mt-n4 mx-n4 mb-n5">
              <div class="bg-soft-info">
                <div class="card-body pb-4 mb-5">
                  <div class="row">
                    <div class="col-md">
                      <div class="row align-items-center">
                        <div class="col-md-auto">
                          <div class="avatar-md mb-md-0 mb-4">
                            <div class="avatar-title bg-white rounded-circle">
                              <span class="ri ri-wallet-line text-primary fs-20"></span>
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-md">
                          <h4 class="fw-semibold" id="ticket-title">
                            #{{ withdraw["transaction"]["transaction_number"] }}
                          </h4>
                          <div class="hstack gap-3 flex-wrap">
                            <div class="text-muted">
                              <i class="ri-user-line align-bottom me-1"></i><span id="ticket-client">{{
                                  withdraw["transaction"]["user"]["name"]
                              }}</span>
                            </div>
                            <div class="vr"></div>
                            <div class="text-muted">
                              Pengajuan :
                              <span class="fw-medium" id="create-date">{{
                                  globalService.dateFormat2(withdraw["transaction"]["crated_at"])
                              }}</span>
                            </div>
                            <div class="vr"></div>
                            <div class="text-muted">
                              Aksi :
                              <span class="fw-medium" v-if="withdraw['transaction']['validated_at']">{{
                                  globalService.dateFormat2(withdraw["transaction"]["validated_at"])
                              }}</span>
                              <span class="fw-medium" v-else>-</span>
                            </div>
                            <div class="vr"></div>
                            <div v-if="withdraw['transaction']['status'] == 'Y'"
                              class="badge rounded-pill bg-info fs-12">
                              terverifikasi
                            </div>
                            <div v-if="withdraw['transaction']['status'] == 'N'"
                              class="badge rounded-pill bg-danger fs-12">
                              tidak terverifikasi
                            </div>
                            <div v-if="withdraw['transaction']['status'] == 'P'"
                              class="badge rounded-pill bg-warning fs-12">
                              pending
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end row-->
                    </div>
                  </div>
                  <!--end row-->
                </div>
                <!-- end card body -->
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body p-4">
                <h5 class="fs-14 mb-2">Detail Withdraw</h5>
                <div class="form-check card-radio mb-4">
                  <label class="form-check-label" for="shippingMethod02">
                    <span class="fs-20 float-end mt-2 text-wrap d-block fw-semibold">{{
                        numberService.IDR(withdraw["transaction"]["amount"])
                    }}</span>
                    <span class="fs-14 mb-1 text-wrap d-block">{{
                        withdraw["transaction"]["user"]["investor"]["bank_name"]
                    }}</span>
                    <span class="text-muted fw-normal text-wrap d-block">{{
                        withdraw["transaction"]["user"]["investor"][
                        "bank_account_number"
                        ]
                    }}
                      a/n
                      {{
                          withdraw["transaction"]["user"]["investor"][
                          "bank_account_name"
                          ]
                      }}</span>
                  </label>
                </div>
                <form action="" @submit.prevent="confirmation('verified')">
                  <div class="mb-4">
                    <h5 class="fs-14 mb-1">Bukti Transfer</h5>
                    <p class="text-muted">Tambahkan gambar bukti transfer.</p>
                    <div class="row mb-1">
                      <div class="col-md-5">
                        <div class="
                        profile-user
                        position-relative
                        d-inline-block
                        mx-auto
                        mb-4
                      ">
                          <img class="img-thumbnail" alt="logo" width="150"
                            :src="image_preview ?? '/assets/images/small/img-4.jpg'" data-holder-rendered="true" />
                          <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <input id="profile-img-file-input" type="file" class="profile-img-file-input"
                              accept="image/png, image/jpeg, image/jpg" @change="filesChange($event)" />
                            <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                              <span class="
                              avatar-title
                              rounded-circle
                              bg-light
                              text-body
                            ">
                                <i class="ri-camera-fill"></i>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <h5 class="fs-14 mb-1">Keterangan</h5>
                    <p class="text-muted">Tambahkan keterangan.</p>
                    <textarea class="form-control" type="text" v-model="verification.description" required
                      :disabled="withdraw['transaction']['status'] != 'P'" />
                  </div>
                  <div class="float-end">
                    <router-link :to="{ name: 'withdraw' }" type="button"
                      class="btn btn-warning waves-effect waves-light me-2"><i
                        class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                      Kembali
                    </router-link>
                    <button :disabled="send_unverifified" type="button" v-if="withdraw['transaction']['status'] == 'P'"
                      @click="confirmation('unverified')" class="btn btn-danger waves-effect waves-light me-2">
                      <i class="bx font-size-16 align-middle me-2"
                        :class="{ 'bx-loader bx-spin': send_unverifified, 'bx-x': !send_unverifified }"></i>
                      Unverifikasi
                    </button>
                    <button :disabled="send_verifified" type="submit" v-if="withdraw['transaction']['status'] == 'P'"
                      class="btn btn-primary waves-effect waves-light">
                      <i class="bx font-size-16 align-middle me-2"
                        :class="{ 'bx-loader bx-spin': send_verifified, 'bx-check': !send_verifified }"></i>
                      Verifikasi
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
    </div>
    <!-- container-fluid -->
  </div>
</template>

<script>
import moment from "moment";
import { endpoint, host } from "../../host";
import errorService from "../../services/error-service";
import httpService from "../../services/http-service";
import WithdrawShimmer from "./shimmer/WithdrawShimmer.vue";
import numberService from "../../services/number-service";
import globalService from "../../services/global-service";
import Swal from "sweetalert2";
export default {
  components: {
    WithdrawShimmer,
  },

  created() {
    this.id = this.$route.params.id;
    this.showWithdraw();
  },

  data() {
    return {
      send_verifified: false,
      send_unverifified: false,
      load: false,
      id: null,
      withdraw: null,
      moment: moment,
      numberService: numberService,
      globalService: globalService,
      verification: {
        evidance_of_transfer: null,
        description: null,
        status: 'Y',
        _method: 'put',
      },
      image_preview: null,
    };
  },

  methods: {
    async showWithdraw() {
      try {
        let res = await httpService.get(
          endpoint.withdraw + `/show/${this.id}`,
        );
        this.withdraw = res.data.data;
        this.assignParams(this.withdraw);
        this.description();
      } catch (error) {
        errorService.displayError(error.response);
      }
    },

    confirmation(status) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah anda yakin ingin " + (status == 'verified' ? 'menyetujui withdraw ?' : 'menolak withdraw ?'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#405189",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, lanjutkan",
        cancelButtonText: "Tidak, batalkan",
        buttonsStyling: true,
      }).then((isConfirm) => {
        if (isConfirm.value === true) {
          if (status == 'verified') {
            this.verified();
          } else {
            this.unverified();
          }
        }
      });
    },

    async unverified() {
      try {
        this.send_unverifified = true;
        let res = await httpService.post(
          endpoint["withdraw"] + `/validate/${this.withdraw.id}`,
          {
            _method: 'put',
            status: 'N',
          }
        );
        this.send_unverifified = false;
        if (res.status == 200) {
          this.showWithdraw();
          Swal.fire({
            title: "Berhasil",
            text: "withdraw tidak terverifikasi",
            icon: "success",
          });
        }
      } catch (error) {
        this.send_unverifified = false;
        errorService.displayError(error);
      }
    },

    async verified(status) {
      try {
        this.send_verifified = true;
        let formData = new FormData();
        for (const key in this.verification) {
          formData.append(key, this.verification[key] ?? "");
        }
        let res = await httpService.post(
          endpoint["withdraw"] + `/validate/${this.withdraw.id}`, formData
        );
        this.send_verifified = false;
        if (res.status == 200) {
          this.showWithdraw();
          Swal.fire({
            title: "Berhasil",
            text: "withdraw " + (status == 'verified' ? 'terverifikasi' : 'tidak terverifikasi'),
            icon: "success",
          });
        }
      } catch (error) {
        this.send_verifified = false;
        errorService.displayError(error);
      }
    },

    description() {
      if (this.withdraw['transaction']['description'] == null) {
        let bank_name = this.withdraw["transaction"]["user"]["investor"]["bank_name"];
        let bank_account_name = this.withdraw["transaction"]["user"]["investor"]["bank_account_name"];
        let bank_account_number = this.withdraw["transaction"]["user"]["investor"]["bank_account_number"];
        this.verification.description = `${bank_name} ${bank_account_number} a/n ${bank_account_name}`;
      }
    },

    filesChange(event) {
      this.verification.evidance_of_transfer = event.target.files[0];
      this.image_preview = URL.createObjectURL(this.verification.evidance_of_transfer);
    },

    assignParams(params) {
      this.verification.description = params.transaction.description;
      this.image_preview = params.evidance_of_transfer ? `${host}/storage/uploads/evidance_of_transfer/` + params.evidance_of_transfer : null;
    },
  },
};
</script>

<style>
.profile-user .profile-photo-edit {
  right: -4px !important;
  bottom: -8px !important;
}
</style>